<template>
    <div class="onLineMessage">
        <div class="banner">
            <img src="./uploads/banner3.jpg" alt="">
        </div>

        <div class="lineMessage wapper">
            <div class="contact">
                <h3>联系我们</h3>
                <p>感谢您联系我们,您在这里可以留言关于业务合作,产品疑问和相关建议</p>
            </div>

            <div class="sendMessage">
                <div class="sendMessageContent" :style="{marginBottom:(showVerifyName?'':'22px')}">
                    <label for="userName">
                        <span>姓名:</span>
                        <input type="text" placeholder="请输入姓名" @blur="verifyUsername"
                            name="username" id="userName" v-model="form.username">
                    </label>
                    <div class="verifyUanme verify" ref="username" :style="{visibility: (!showVerifyName?'hidden':'')}"
                        v-if="showVerifyName">姓名 是必选字段！</div>
                </div>
                
                <div class="sendMessageContent" >
                    <label>
                        <span>邮箱:</span>
                        <input type="text" placeholder="请输入电子邮件" @blur="verifyEmail" 
                            name="email" v-model="form.email">
                    </label>
                    <div :style="{visibility: (!showVerifyEamil?'hidden':'')}"
                     class="verifyEmail verify" ref="emailRef">电子邮件 是必选字段！</div>
                </div>

                <div class="sendMessageContent" >
                    <label>
                        <span>电话:</span>
                        <input type="text" placeholder="请输入电话" @blur="verifyPhone" 
                            name="phone" v-model="form.phone">
                    </label>
                    <div :style="{visibility: (!showVerifyphone?'hidden':'')}"
                     class="verifyPhone verify" ref="phoneRef">电话 是必选字段！</div>
                </div>

                <div class="sendMessageContent">
                    <label>
                        <span>内容:</span>
                        <textarea name="content" id="" @blur="verifyCotent" 
                            v-model="form.content" cols="30" rows="10"></textarea>
                    </label>
                    <div :style="{visibility: (!showContent?'hidden':'')}" ref="centent"
                         class="verifyContent verify">内容 是必选字段！</div>
                </div>

                <div class="btn">
                    <button type="button" @click="submit">提交</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OnLineMessage',

    data() {
        return {
            form: {
                username: '',
                email: "",
                content: '',
                phone: '',
            },
            showVerifyName: false,
            showVerifyEamil: false,
            showContent: false,
            showVerifyphone: false,
        };
    },

    mounted() {
        
    },

    methods: {
        submit() {
            this.verifyUsername()
            this.verifyEmail()
            this.verifyCotent()

            if(this.showContent || this.showVerifyEamil || this.showVerifyName || this.showVerifyphone) {
                return
            }else {
                // 发送请求
                alert('submit')
            }
        },

        verifyUsername() {
            if(this.form.username === "") {
                this.showVerifyName = true
                
            }else {
                this.showVerifyName = false
               
            }
        },
        verifyEmail() {
            this.$refs.emailRef.innerHTML = "电子邮件 是必选字段！"
            let reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
            if(this.form.email === "") {
                this.showVerifyEamil = true
                return
            }else {
                this.showVerifyEamil = false

                if(!reg.test(this.form.email) && this.form.email.trim() !== "") {
                    this.showVerifyEamil = true
                    this.$refs.emailRef.innerHTML = "请输入正确的邮箱"
                    return
                }else {
                    this.showVerifyEamil = false
                }
            }
        },
        verifyPhone() {
            this.$refs.phoneRef.innerHTML = "电话 是必选字段！"
            let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
            if(this.form.phone === "") {
                this.showVerifyphone = true
                return
            }else {
                this.showVerifyphone = false

                if(!reg.test(this.form.phone) && this.form.phone.trim() !== "") {
                    this.showVerifyphone = true
                    this.$refs.phoneRef.innerHTML = "请输入正确的电话"
                    return
                }else {
                    this.showVerifyphone = false
                }
            }
        },
        verifyCotent() {
            if(this.form.content === "") {
                this.showContent = true
            }else {
                this.showContent = false
            }

            
        }
    },
};
</script>

<style lang="less" scoped>
    .onLineMessage {
        .banner {
            img {
                width: 100%;
            }
        }
        .contact {
            margin: 15px auto;
            border-bottom: 1px solid #000;
            p {
                margin: 20px auto;
                text-indent: 2em;
            }
        }
        .sendMessage {
            padding:15px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .sendMessageContent {
                display: flex;
                // margin-bottom: 20px;
                flex-direction: column;
                label {
                    display: flex;
                    span {
                        margin-right: 5px;
                        width: 100px;
                        height: 32px;
                        text-align: right;
                        &::before{
                            display: inline-block;
                            margin-right: 4px;
                            color: #ff4d4f;
                            font-size: 14px;
                            font-family: SimSun,sans-serif;
                            line-height: 1;
                            content: "*";
                        }                         
                    }
                    .emailSpan {
                        margin-left: -32px;
                    }
                    input {
                        border: 1px solid rgba(0,0,0,.6);
                        width: 400px;
                        height: 32px;
                        line-height: 32px;
                        padding: 4px 11px;
                        transition: color .3s;
                        &:hover {
                            border-color: #40a9ff;
                        }
                        &:placeholder-shown {
                            padding: 4px 11px;
                            color: rgba(0,0,0,.06);
                        }
                    }
                    textarea {
                        width: 400px;
                        height: 150px;
                        resize: none;
                        outline: none;
                        border: 1px solid rgba(0,0,0,.6);
                        &:hover {
                            border-color: #40a9ff;
                        }
                        &:placeholder-shown {
                            padding: 4px 11px;
                            color: rgba(0,0,0,.06);
                        }
                    }
                }
                .verify {
                    color: #ff4d4f;
                    margin-left: 105px;
                    height: 24px;
                }
            }
            .btn {
                width: 0px;
                text-align: center;
                button {
                    border:none;
                    outline: none;
                    border-radius: 50px;
                    cursor: pointer;
                    color:#fff;
                    width: 84px;
                    height: 32px;
                    line-height: 32px;
                    text-align: center;
                    background: #1890ff;
                    border-color: #1890ff; 
                    margin: 0 auto;
                    &:hover {
                        background: #40a9ff;
                        border-color: #40a9ff;
                    }
                }
            }
        }
    }
    
</style>